








































































































import { Component, Mixins } from 'vue-property-decorator'
import { View } from 'client-website-ts-library/plugins'
import TopBottomSection from '@/components/TopBottomSection.vue'
import SideBySideSection from '@/components/SideBySideSection.vue'
import NavigationLink from '@/components/Common/NavigationLink.vue'
import ContactForm from '@/components/ContactForm.vue'

@Component({
  components: {
    TopBottomSection,
    NavigationLink,
    SideBySideSection,
    ContactForm,
  },
})
export default class Commercial extends Mixins(View) {
}
